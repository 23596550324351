import React from "react";
import {Helmet} from "react-helmet";
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Header from "./header";
import SEO from "./seo"

const Layout = ({children}) => {
    const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)


    return (
        <>

        <Header siteTitle={data.site.siteMetadata.title}/>
        <div
            style={{
                margin: `0 auto`,
                maxWidth: 960,
                padding: `0px 1.0875rem 1.45rem`,
                paddingTop: 0,
            }}
        >

            <main>{children}</main>

        </div>
        <footer className="footer">
            <div className="container">
            © {new Date().getFullYear()}, iNext - eBusiness together.
            </div>
        </footer>
        </>
    )

}

// const Wrapper = styled.div`
//   min-height: 100vh;
// `
//
// const Footer = styled.footer`
//   text-align: center;
//   margin: 24px;
// `

export default Layout
